/********** FONTS ***********/
@font-face {
  font-family: "Fondamento";
  src: url('./assets/fonts/fondamento-regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cardo";
  src: url('./assets/fonts/Cardo-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cardo";
  src: url('./assets/fonts/Cardo-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cardo";
  src: url('./assets/fonts/Cardo-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

/********** ELEMENTS *********/
body {
  background-color: #282c34;
  color: white;
  margin: 0;
  font-family: "Cardo",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 { font-family: "Fondamento"; font-size: 2.75rem; }
a { text-decoration: none; color: #61dafb;}
a:visited { color: #61dafb;; }

/********** GENERAL - LOOK & FEEL **********/
.fine-print { font-size: 1rem; font-style: italic; }


/********** GENERAL - LAYOUT **********/
.centered-flex {
  min-height: 100vh;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: 1.35rem;
}
.grid { display: grid; gap: .3rem; }
.two-column-grid { grid-template-columns: 45% 55%; }
.offscreen { position: absolute; left: -9999px; }
.hide { display: none; }

/********* GENERAL - FORM CONTROLS *********/
.button {
  display: inline-block;
  color: #333;
  text-align: center;
  padding: .3rem .3rem;
  border-radius: .25rem;
  border: .5px #333 solid;
  font-size: .65rem;
  text-transform: uppercase;
  font-family: 'Fondamento';
  text-decoration: none;
  cursor: pointer; 
  background-color: #61dafb;
  font-size: 1.1rem;
  a { text-decoration: none; color: #333;}
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

input[type="text"],
input[type="password"],
input[type="email"], 
textarea {
  font-family: 'Cardo', sans-serif;
  font-size: 1.15rem;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label { 
  margin-top: .25rem; 
  .valid, .invalid { margin-left: .75rem;}
  .valid { color: limegreen; }
  .invalid { color: red; }
}

.note-box { 
  text-wrap: wrap;
  padding: .5rem;
}

.instructions {
  font-size: 1rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 1rem;
  position: relative;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

/********** PAGES - LANDING **********/
.App-link { color: #61dafb; }
.Landing {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-bottom: 3rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.landing a, .landing a:visited { color: white; }

/********** PAGES - REGISTRATION **********/
#Registration {
  flex: 0 0 22em;
  button { margin-top: 2.5rem; margin-bottom: 2rem;}
  label { min-width: 13rem; }
  input[type="checkbox"] { margin-top: .80rem; justify-self: flex-start; }
  .note-box { max-width: 16rem; margin-top: .85rem; }
}

/********** PAGES - LOGIN **********/
#Login {
  flex: 0 0 22em;
  button { margin-top: 2.5rem; margin-bottom: 2rem; }
  .note-box { max-width: 16rem; margin-top: .85rem; }
}